import React from "react";
import NavBar from './../components/navbar'
import Footer from "../components/footer";
import Img1 from "./../images/slide-home-grupo-lae-empresas.jpg"

const AvisoLegal = () => {
    return ( 
        <>
            <NavBar />
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto">
                        <h1 className="text-white mb-1 text-white">AVISO LEGAL</h1>
                        <span className='linea-header'></span>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container politicas text-left">
                    <p>En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), la entidad LAE Formación Programada, S.L. le informa que es titular del sitio web. De acuerdo con la exigencia del artículo 10 de la citada Ley, informa de los siguientes datos:</p>
                    <ul>
                        <li>El titular de este sitio web es: LAE Formación Programada, S.L.</li>
                        <li>NIF: B20910121</li>
                        <li>Domicilio social: C/ Juan F. Gilisagasti 2 – Oficina 215, 20018 Donostia – San Sebastián (Gipuzkoa).</li>
                    </ul>
                    <p>Datos de Inscripción en el Registro Mercantil de Gipuzkoa; Tomo: 2239 Libro.: 0 Folio: 98 Hoja: SS-27160 Inscripción: 9ª.</p>
                    <h5>USUARIOS</h5>
                    <p>En cumplimiento de lo establecido por el artículo 21 de la Ley de Servicios de la Sociedad de la Información, Nombre empresa podrá remitirle, por correo electrónico o a través de cualquier otro medio análogo, información publicitaria relacionada con la actividad de LAE Formación Programada, S.L. </p>
                    <p>El acceso y/o uso de este portal le atribuye la condición de USUARIO, que acepta, desde dicho acceso y/o uso, las Condiciones Generales de Uso aquí reflejadas. Las citadas Condiciones serán de aplicación independientemente de las Condiciones Generales de Contratación que en su caso resulten de obligado cumplimiento.</p>
                    <h5>USO DEL PORTAL</h5>
                    <p>La página web proporciona el acceso a multitud de informaciones, servicios, programas o datos (en adelante, “los contenidos”) en Internet pertenecientes a LAE Formación Programada, S.L. o a sus licenciantes a los que el USUARIO pueda tener acceso. El USUARIO asume la responsabilidad del uso del portal. Dicha responsabilidad se extiende al registro que fuese necesario para acceder a determinados servicios o contenidos.</p>
                    <p>En dicho registro el USUARIO será responsable de aportar información veraz y lícita. Como consecuencia de este registro, al USUARIO se le puede proporcionar una contraseña de la que será responsable, comprometiéndose a hacer un uso diligente y confidencial de la misma. El USUARIO se compromete a hacer un uso adecuado de los contenidos y servicios que LAE Formación Programada, S.L. ofrece a través de su portal y con carácter enunciativo pero no limitativo, a no emplearlos para:</p>
                    <ul>
                        <li>Incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público</li>
                        <li>Difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o atentatorio contra los derechos humanos</li>
                        <li>Provocar daños en los sistemas físicos y lógicos de LAE Formación Programada, S.L., de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados;</li>
                        <li>Intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar manipular sus mensajes. LAE Formación Programada, S.L. se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultaran adecuados para su publicación. En cualquier caso, LAE Formación Programada, S.L. no será responsable de las opiniones vertidas por los usuarios a través de los foros, chats, u otras herramientas de participación.</li>
                    </ul>
                    <h5>PROPIEDAD INTELECTUAL E INDUSTRIAL</h5>
                    <p>Todos los derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de LAE Formación Programada, S.L. El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de LAE Formación Programada, S.L. Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El USUARIO deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en el las páginas de LAE Formación Programada, S.L.</p>
                    <p>LAE Formación Programada, S.L. será titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.).</p>
                    <h5>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</h5>
                    <p>LAE Formación Programada, S.L. no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad del portal o la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.</p>
                    <h5>MODIFICACIONES</h5>
                    <p>LAE Formación Programada, S.L. se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su portal, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que éstos aparezcan presentados o localizados en su portal.</p>
                    <h5>ENLACES</h5>
                    <p>En el caso de que en la página web se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet, LAE Formación Programada, S.L. no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso LAE Formación Programada, S.L. asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet.</p>
                    <p>Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.</p>
                    <h5>DERECHO DE EXCLUSIÓN</h5>
                    <p>LAE Formación Programada, S.L. se reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones Generales de Uso.</p>
                    <h5>GENERALIDADES</h5>
                    <p>LAE Formación Programada, S.L. perseguirá el incumplimiento de las presentes condiciones, así como cualquier utilización indebida de su portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.</p>
                    <h5>MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN</h5>
                    <p>LAE Formación Programada, S.L. podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen.</p>
                    <p>La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas.</p>
                    <h5>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h5>
                    <p>La relación entre LAE Formación Programada, S.L. y el USUARIO se regirá por la normativa española vigente y cualquier controversia se someterá a los Juzgados y tribunales de la ciudad anteriormente indicada.</p>
                </div>
            </div>
            <Footer />
            <style jsx="true">{`
                .bg-imagen{
                    background-image: url(${`'${Img1}'`});
                    min-height: 225px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                `}
            </style>
        </>
     );
}
 
export default AvisoLegal;