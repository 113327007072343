import React from 'react';
import { useLocation } from 'react-router-dom';
import {Container, Nav, Navbar} from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube} from "react-icons/fa";
import Logo from './../images/Logo Grupolae_RGB.png'
import { Link } from 'react-router-dom';

function CollapsibleExample() {

  const location = useLocation()

  return (
    <>
      {location.pathname !== "/" ? 
        <div className='container'><Link to ="/" ><img src={Logo} alt="Grupo Lae" style={{width: 150, margin: '10px auto'}}/></Link></div>
      :
        <Navbar collapseOnSelect expand="lg" className="navbar">
          <Container>
            <Nav style={{ fontSize: '11px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Nav.Link href="mailto:info@grupolae.net" className="color-light">info@grupolae.net</Nav.Link>
                <div className='linea-menu'></div>
                <Nav.Link href="tel:0034943215111" className="color-light">+34 943 211 151</Nav.Link>
                <div className='linea-menu'></div>
                <Nav.Link href="mailto:cv@grupolae.net" className="color-light">trabaja con nosotros</Nav.Link>
            </Nav>
            <Nav>
                <Nav.Link eventKey={2} href="https://www.facebook.com/grouplae/" target='_blank' className="mx-1 navb-link">
                    <FaFacebookF className="nav-icon" />
                </Nav.Link>
                <Nav.Link eventKey={2} href="https://www.instagram.com/grupo_lae/" target='_blank' className="mx-1 navb-link">
                    <FaInstagram className="nav-icon" />
                </Nav.Link>
                <Nav.Link eventKey={2} href="https://www.linkedin.com/company/laacademia-empresas-s-l-/?viewAsMember=true" target='_blank' className="mx-1 navb-link">
                    <FaLinkedinIn className="nav-icon" />
                </Nav.Link>
                <Nav.Link eventKey={2} href="https://twitter.com/grupo_lae" target='_blank' className="mx-1 navb-link">
                    <FaTwitter className="nav-icon" />
                </Nav.Link>
                <Nav.Link eventKey={2} href="https://www.youtube.com/@GrupoLae" target='_blank' className="mx-1 navb-link">
                    <FaYoutube className="nav-icon" />
                </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      }
    </>
  );
}

export default CollapsibleExample;