import React from "react";
import NavBar from './../components/navbar'
import Footer from "../components/footer";
import Img1 from "./../images/slide-home-grupo-lae-empresas.jpg"
import { Link } from "react-router-dom";

const PoliticaPrivacidad = () => {
    return ( 
        <>
            <NavBar />
            <div className="container-fluid bg-imagen d-flex" style={{alignItems: "center"}}>
                <div className="container">
                    <div className="text-center mx-auto">
                        <h1 className="text-white mb-1 text-white">POLÍTICA DE COOKIES</h1>
                        <span className='linea-header'></span>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-5">
                <div className="container politicas text-left">
                    <h5>¿QUÉ ES UNA COOKIE?</h5>
                    <p>Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación o personalizas la forma en la que se muestra el contenido. Los usos concretos que hacemos de estas tecnologías se describen a continuación.</p>
                    <h5>¿QUIÉN UTILIZA LA INFORMACIÓN DE LAS COOKIES?</h5>
                    <ul>
                        <li><strong>LAE Formación Programada, S.L.</strong></li>
                        <li>B20910121</li>
                        <li>Calle Juan F. Gilisagasti 2 – oficina 215, 20.018 Donostia – San Sebastián (Gipuzkoa)</li>
                    </ul>
                    <h5>¿QUÉ TIPOS DE COOKIES EXISTEN?</h5>
                    <p>Las Cookies pueden clasificarse, en virtud de sus características propias, dentro de diversas categorías.</p>
                    <p>1 – Según la entidad que la gestione:</p>
                    <ul>
                        <li>Cookies propias: Son aquellas que se envían a tu ordenador y son gestionadas exclusivamente por nosotros para el mejor funcionamiento del Sitio Web.</li>
                        <li>Cookies de terceros: Son aquellas que se envían a tu ordenador y no son gestionadas por nosotros sino por una tercera entidad. </li>
                    </ul>
                    <p>2 – Según el plazo de tiempo que permanecen activadas:</p>
                    <ul>
                        <li>Cookies de sesión: Este tipo de cookies recaban y almacenan datos únicamente mientras el Usuario accede al Sitio Web.</li>
                        <li>Cookies persistentes: Mediante este tipo de cookies los datos siguen almacenados y pueden ser accedidos y tratados durante el periodo definido por el responsable de la cookie, y puede ir de unos minutos a varios años.</li>
                    </ul>
                    <p>3 – Según la finalidad:</p>
                    <ul>
                        <li>Cookies técnicas: Son aquellas que permiten al usuario la navegación a través del sitio web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que el mismo ofrece, como por ejemplo, controlar el  tráfico  y  la comunicación   de   datos,   identificar   la   sesión,   acceder   a   partes   de   acceso restringido, recordar los elementos que integran un pedido,  realizar el proceso de compra  de  un  pedido,  realizar  la  solicitud  de  inscripción  o  participación  en  un evento,   utilizar   elementos   de   seguridad   durante   la   navegación,   almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.</li>
                        <li>Cookies de personalización: Son aquéllas que permiten al usuario accede al servicio con algunas características de carácter general predefinidas, como por ejemplo el idioma, la configuración regional desde donde accede al servicio, etc.</li>
                        <li>Cookies de análisis: Son aquéllas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que  están    La  información  recogida  mediante  este  tipo  de  cookies  se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para  la  elaboración  de  perfiles  de  navegación  de  los  usuarios  de  dichos  sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.</li>
                        <li>Cookies publicitarias: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, se haya incluido en el sitio web, aplicación o plataforma desde la que presta el servicio solicitado en base a los criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.</li>
                        <li>Cookies de publicidad comportamental: Son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en su página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.</li>
                    </ul>
                    <h5>¿QUÉ TIPOS DE COOKIES UTILIZA ESTE SITIO WEB?</h5>
                    <p>A continuación, se especifican las cookies en concreto empleadas en el presente Sitio Web:</p>
                    <table className="table">
                        <thead className="thead-light">
                            <th>Nombre</th>
                            <th>Tipo</th>
                            <th>Duración</th>
                            <th width="40%">Finalidad</th>
                            <th>Propias/Terceros</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Técnica</td>
                                <td>Técnica</td>
                                <td>De sesión</td>
                                <td>Información de sesión para permitir la funcionalidad del sitio web o plataforma.</td>
                                <td>Propia</td>
                            </tr>
                            <tr>
                                <td>player, vuid, _ga</td>
                                <td>Analíticas</td>
                                <td>Al cerrar sesión</td>
                                <td>Vimeo utiliza algunas cookies para comprobar si los usuarios seleccionan algunas funcionalidades o ciertas preferencias. Puedes saber más sobre cada cookie utilizada por Vimeo en su lista de cookies actual.</td>
                                <td>Tercero (Vimeo)</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Esta tabla puede ser actualizada conforme a las cookies empleadas en cada momento. Te aconsejamos que consulte nuestra Política de Cookies de forma regular.</p>
                    <h5>¿SE REALIZAN TRANFERENCIAS DE DATOS A TERCEROS PAÍSES?</h5>
                    <p>Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta política de cookies en sus correspondientes políticas (Ver los enlaces facilitados en la tabla anterior, en el apartado de cookies de terceros).</p>
                    <h5>¿CÓMO DESHABILITAR Y BLOQUAR COOKIES?</h5>
                    <p>Te informamos que, en cualquier caso, puedes deshabilitar o bloquear las cookies activando la configuración de tu navegador, permitiéndote rechazar la instalación de todas o algunas de las cookies. En caso de no permitir la instalación de cookies es posible que no puedas acceder a algunas de las funcionalidades del presente sitio web, y por tanto, tu experiencia como usuario en el sitio web resulte menos satisfactoria.</p>
                    <p>Puedes permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador instalado en tu ordenador. Para más información sobre cómo ajustar sus configuraciones de cookies en los siguientes navegadores, te remitimos al enlace pertinente: <Link to="https://support.google.com/chrome?source=404&sjid=15234270238737036187-EU#topic=7439538">Chrome</Link>, <Link to="https://support.apple.com/es-es/guide/safari/sfri11471/mac">Safari</Link>, <Link to="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">Firefox</Link>, <Link to="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer</Link>, <Link to="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</Link>.</p>
                    <h5>¿QUÉ DERECHOS TIENES SOBRE LOS DATOS PERSONALES APORTADOS?</h5>
                    <p>Como usuario, puedes ejercitar los siguientes derechos:</p>
                    <ul>
                        <li>Derecho de acceso: Tienes derecho a obtener por parte de la empresa confirmación de si se están tratando o no tus datos personales que, y en su caso, qué datos trata.</li>
                        <li>Derecho de rectificación: Tienes derecho a obtener por parte de la empresa, la rectificación de los datos personales inexactos que te conciernen. En la solicitud de rectificación deberás indicar los datos que deseas que se modifiquen.</li>
                        <li>Derecho de oposición: Tienes derecho a oponerte en cualquier momento, por motivos relacionados con tu situación particular, a que datos personales que te conciernan sean objeto de un tratamiento basado en el interés legítimo de la empresa. En ese caso, la empresa dejará de tratar los datos personales, salvo que acreditemos motivos legítimos imperiosos para el tratamiento que prevalezcan sobre tus intereses, derechos y libertades, o para la formulación, el ejercicio o la defensa de reclamaciones.</li>
                        <li>Derecho de supresión: Tienes derecho a obtener por parte de la empresa, la supresión de los datos personales que te conciernan.</li>
                        <li>Derecho a la portabilidad de los datos: Tienes derecho a que la empresa transfiera tus datos personales a otro responsable del tratamiento. Solo es posible ejercer este derecho cuando el tratamiento esté basado en la ejecución de un contrato o en tu consentimiento y el tratamiento se realice por medios automatizados.</li>
                        <li>Derecho a la limitación del tratamiento: Tienes derecho a solicitarnos que suspendamos el tratamiento de tus datos cuando: impugnes la exactitud de los datos, mientras la empresa verifica dicha exactitud; hayas ejercido el derecho de oposición al tratamiento de tus datos, mientras que se verifica si los motivos legítimos de la empresa prevalecen sobre los tuyos como interesado.</li>    
                        <li>Derecho a no ser objeto de decisiones individuales automatizadas, incluida la elaboración de perfiles: Tienes derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles, que produzca efectos jurídicos o te afecte significativamente de modo similar.</li>
                    </ul>
                    <p>En cualquier momento, podrás ejercitar estos Derechos, mediante un escrito y acreditando tu personalidad dirigido al responsable del tratamiento, a la dirección que aparece más arriba.</p>
                    <p>Además, podrás presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideras que el tratamiento de datos personales infringe la legislación aplicable.</p>
                </div>
            </div>
            <Footer />
            <style jsx="true">{`
                .bg-imagen{
                    background-image: url(${`'${Img1}'`});
                    min-height: 225px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    margin: 0;
                `}
            </style>
        </>
     );
}
 
export default PoliticaPrivacidad;