import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return ( 
        <>
            <footer id="colophon" className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <h3 className="title mb-5">Tu partner perfecto <br/> para el éxito</h3>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
                            <div className="f-cont-l">
                                <p><small className="color-gray">INFORMACIÓN LEGAL</small></p>
                                <ul className="list-unstyled contact-links">
                                    <li><Link to="/aviso-legal" target="_blank">Aviso legal</Link></li>
                                    <li><Link to="/politica-privacidad" target="_blank">Política de privacidad</Link></li>
                                    <li><Link to="/politica-cookies" target="_blank">Política de cookies</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
                            <div className="f-cont-l">
                                <p><small className="color-gray">CONTACTO</small></p>
                                <ul className="list-unstyled contact-links">
                                    <li><Link to="tel:+34943211151">+34 943 211 151 </Link></li>
                                    <li><Link to="mailto:info@grupolae.net">info@grupolae.net</Link></li>
                                    <li><Link to="mailto:cv@grupolae.net">Trabaja con nosotros</Link></li>
                                    {/* <li><Link to="#" target="_blank">Sobre Nosotros</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
                            <div className="f-cont-l">
                                <p><small className="color-gray">REDES SOCIALES</small></p>
                                <ul className="list-unstyled">
                                    <li><Link to="https://www.facebook.com/grouplae/">Facebook</Link></li>
                                    <li><Link to="https://www.linkedin.com/company/laacademia-empresas-s-l-/?viewAsMember=true">Linkedin</Link></li>
                                    <li><Link to="https://www.instagram.com/grupo_lae/">Instagram</Link></li>
                                    <li><Link to="https://twitter.com/grupo_lae">Twitter</Link></li>
                                    <li><Link to="https://www.youtube.com/@GrupoLae">Youtube</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
                            <div className="f-cont-l">
                                <p><small className="color-gray">DELEGACIONES</small></p>
                                <ul className="list-unstyled">
                                    <li>Madrid</li>
                                    <li>Barcelona</li>
                                    <li>Valencia</li>
                                    <li>Málaga</li>
                                    <li>San Sebastián</li>
                                    <li>Sevilla</li>
                                    <li>Marbella</li>
                                    <li>Alicante</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-4">
                                <div className="text-right">
                                    <p><small className="color-gray">&copy; GRUPO LAE 2023. Todos los derechos reservados</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
     );
}
 
export default Footer;